import { fetchProteinData } from "./fetchProteinData";

const formatData = async (data, query) => {
  const formattedData = { nodes: [], links: [] };

  const parent = await fetchProteinData(data[0]["source"]);

  formattedData["nodes"].push({
    id: data[0]["source"],
    gene_label: parent["gene_label"] ? parent["gene_label"] : data[0]["source"],
    protein_label: parent["protein_label"]
      ? parent["protein_label"]
      : data[0]["source"],
    known: "parent",
    group: 1,
  });

  for await (const i of data) {
    const response = await fetchProteinData(i["target"]);

    formattedData["nodes"].push({
      id: i["target"],
      gene_label: response["gene_label"] ? response["gene_label"] : i,
      protein_label: response["protein_label"] ? response["protein_label"] : "",
      group: 2,
      known: i["known"],
    });
  }

  data.map((item) => {
    return formattedData["links"].push({
      source: item["source"],
      target: item["target"],
      value: item["score"],
      known: item["known"]
    });
  });

  return formattedData;
};

export const fetchNetwork = async (query) => {
  // const dataSrc = 'https://gist.githubusercontent.com/Emceelamb/c7d6287a1830637325bf6e662d67f37c/raw/d8a48fb76991c2c5f50ce6ae1dbc786c7e9aade0/uniprot_opa1_pmid.csv';
  // const dataSrc = 'https://gist.githubusercontent.com/Emceelamb/c7d6287a1830637325bf6e662d67f37c/raw/d8a48fb76991c2c5f50ce6ae1dbc786c7e9aade0/uniprot_pmid_nme4.csv'
  const response = await fetch(`/api/graph_network?accession_id=${query}`);
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  if (data["graph_network"].length === 0) {
    return {};
  }
  const formattedData = await formatData(data["graph_network"]);
  return formattedData;
};


export const fetchNetworkWithContext = async (query, contextQuery) => {
  // const dataSrc = 'https://gist.githubusercontent.com/Emceelamb/c7d6287a1830637325bf6e662d67f37c/raw/d8a48fb76991c2c5f50ce6ae1dbc786c7e9aade0/uniprot_opa1_pmid.csv';
  // const dataSrc = 'https://gist.githubusercontent.com/Emceelamb/c7d6287a1830637325bf6e662d67f37c/raw/d8a48fb76991c2c5f50ce6ae1dbc786c7e9aade0/uniprot_pmid_nme4.csv'
  const response = await fetch(`/api/graph_network?accession_id=${query}&context_control=${contextQuery}`);
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  if (data["graph_network"].length === 0) {
    return {};
  }
  const formattedData = formatData(data["graph_network"]);
  return formattedData;
};
