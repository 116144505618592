import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ZoomableSunburst } from "./ZoomableSunburst";
import { timeFormat } from "d3";

const formatTime = timeFormat("%B %d, %Y");

export default function ProteinSlideout({
  protein,
  sendSelectedProteinToParent,
}) {
  const [open, setOpen] = useState(false);

  const handleclick = () => {
    if (setOpen === false) {
      setOpen(true);
    } else {
      setOpen(false);
      let timer1 = setTimeout(() => sendSelectedProteinToParent(null), 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  };

  useEffect(() => {
    setOpen(true);
    // console.log(protein["pmids"]["sunburst"]["children"].length)
  }, [protein]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-[999]"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col dark:bg-[#171d32]/80 bg-[#171d32]/95 shadow-xl overflow-y-scroll">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <div>
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-bold text-white"
                        >
                          {protein["data"]["gene_label"]}
                        </h2>

                        <p className="text-sm font-light text-gray-300">
                          {protein["data"]["protein_label"]}
                        </p>
                      </div>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          onClick={handleclick}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-6">
                      {protein["pmids"] ? (
                        <div>
                          <div className="relative">
                            <div className="flex justify-center">
                              <ZoomableSunburst
                                data={protein["pmids"]}
                                width={300}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                        <div>
                          <dt className="text-sm font-medium text-white  sm:w-40 sm:flex-shrink-0">
                            Uniprot Accession ID
                          </dt>
                          <dd className="mt-1 text-sm text-gray-300 sm:col-span-2">
                            {protein["data"]["accession"]}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-white sm:w-40 sm:flex-shrink-0">
                            Date Created
                          </dt>
                          <dd className="mt-1 text-sm text-gray-300  sm:col-span-2">
                            <p>
                              {formatTime(
                                new Date(protein["data"]["date_created"])
                              )}
                            </p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-white sm:w-40 sm:flex-shrink-0">
                            Function
                          </dt>
                          <dd className="mt-1 text-sm text-gray-200 sm:col-span-2">
                            <p>{protein["data"]["function"]}</p>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
