export const SearchContextComponent = ({
  label,
  setQuery,
  submitQuery,
  innerRef,
  query,
  contextQuery,
  setContextQuery,
}) => {
  return (
    <form onSubmit={submitQuery} ref={innerRef}>
      <div className="mt-1 relative rounded-md space-y-2">
        <label
          htmlFor={label}
          className="block text-xs font-light uppercase text-white"
        >
          {label}
        </label>
        <input
          onChange={(e) => setQuery(e.target.value.toUpperCase())}
          type="text"
          name={label}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm p-2 bg-transparent border uppercase"
          placeholder="Accession ID"
          value={query}
        />
        <label
          htmlFor="context"
          className="block text-xs font-light uppercase text-white"
        >
          Context Control
        </label>
        <input
          onChange={(e) => setContextQuery(e.target.value.toUpperCase())}
          type="text"
          name="context"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm p-2 bg-transparent border uppercase"
          placeholder="Optional Context"
          value={contextQuery}
        />
        <button
          type="Submit"
          className="w-full inline-flex justify-center items-center px-2.5 py-1.5 shadow-sm hover:text-black text-xs font-light text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-[#183965] transition "
        >
          Submit
        </button>
      </div>
    </form>
  );
};
