import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./App.css";
import Network from "./containers/NetworkContainer";
import Network2D from "./containers/NetworkContainer2D";
import TwoNode from "./containers/TwoNodeContainer";
import Testing from "./containers/Testing";

import ReactGA from "react-ga";


export default function App() {
  const trackingId = "UA-220936701-01";

  ReactGA.initialize(trackingId);
  const history = createBrowserHistory();
  ReactGA.pageview(window.location.pathname);

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  useEffect(() => {
    (async () => {
      const response = await fetch("/api/");
      const data = await response.json();
      console.log(data);
      // googleAnalytics();
    })();
  }, []);

  return (
    <Router  history={history}>
      <Routes>
        <Route path="/" element={<TwoNode />} />
        <Route path="/network" element={<Network />} />
        <Route path="/network2d" element={<Network2D />} />
        <Route path="/testing" element={<Testing />} />
      </Routes>
    </Router>
  );
}
