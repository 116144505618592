export const fetchExplainRelationships = async (query1, query2) => {
  const response = await fetch(
    `api/explain_relationships?query=${query1}&proteins=${query2}`
  );
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  return data["relationships"];
};
