import { ForceGraph2D } from "react-force-graph";

import { useState, useRef, useEffect } from "react";
import { scaleLinear } from "d3";
import SpriteText from "three-spritetext";

import { fetchProteinData } from "../utils/fetchProteinData";
import { fetchPMIDs } from "../utils/fetchPMIDs";

import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

export const NetworkGraph2D = ({
  data,
  thirdDimension,
  sendSelectedProteinToParent,
  sendSelectedEdgeToParent,
  width,
}) => {
  const forceGraph = useRef(null);

  const myScale = scaleLinear().domain([1, 0]).range([0, 150]);
  const colorScale = scaleLinear().domain([1, 10]).range(["#eff2f2", "red"]);
  const extraRenderers = [new CSS2DRenderer()];

  const getProtein = async (gene_label) => {
    const data = await fetchProteinData(gene_label);
    const pmids = await fetchPMIDs(gene_label);

    const joined = {
      data: data,
      pmids: pmids,
    };
    sendSelectedProteinToParent(joined);
  };

  useEffect(() => {
    forceGraph.current.d3Force("charge").strength(-500);
  });

  const handleClick = (node) => {
    // Aim at node from outside it
    getProtein(node.id);
  };

  const handleEdgeClick = (link, event) => {
    sendSelectedEdgeToParent(link);
  };

  useEffect(() => {
    if (data["nodes"].length < 1) {
      console.log("not loaded");
      return <>Loading</>;
    }
    // forceGraph.current.d3Force("charge").strength(-100);
    forceGraph.current.d3Force("link").distance((link) => myScale(link.value));
    // forceGraph.current.d3Force("link").distance(link => console.log(link));
  });

  return (
    <div className="block">
      <div>
        <ForceGraph2D
          ref={forceGraph}
          graphData={data}
          width={width}
          backgroundColor="rgba(0,0,0,0)"
          nodeLabel="id"
          nodeAutoColorBy="known"
          nodeRelSize={8}
          nodeCanvasObjectMode={()=>'after'}
          nodeCanvasObject={(node, ctx, globalScale) => {
            // const label = `${node.id}`;
            const label = `${node.gene_label}`;
            const fontSize = 12/globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label).width;
            const bckgDimensions = [textWidth, fontSize].map(
              (n) => n + fontSize * 0.2
            ); // some padding

            ctx.fillStyle = "rgba(55, 55, 55, 0.8)";
            ctx.fillRect(
              node.x - bckgDimensions[0] / 2,
              node.y - bckgDimensions[1] / 2,
              ...bckgDimensions
            );

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = node.color;
            ctx.fillText(label, node.x, node.y);

            node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
          }}
          onNodeClick={handleClick}
          onLinkClick={handleEdgeClick}
          linkAutoColorBy={"known"}
          linkWidth={2}
          linkLabel={(d) => d.value}
          linkCanvasObjectMode={() => "after"}
          linkCanvasObject={(link, ctx) => {
            const MAX_FONT_SIZE = 4;
            const LABEL_NODE_MARGIN = 1.5;

            const start = link.source;
            const end = link.target;

            // ignore unbound links
            if (typeof start !== "object" || typeof end !== "object") return;

            // calculate label positioning
            const textPos = Object.assign(
              ...["x", "y"].map((c) => ({
                [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
              }))
            );

            const relLink = { x: end.x - start.x, y: end.y - start.y };

            const maxTextLength =
              Math.sqrt(Math.pow(relLink.x, 2) + Math.pow(relLink.y, 2)) -
              LABEL_NODE_MARGIN * 2;

            let textAngle = Math.atan2(relLink.y, relLink.x);
            // maintain label vertical orientation for legibility
            if (textAngle > Math.PI / 2) textAngle = -(Math.PI - textAngle);
            if (textAngle < -Math.PI / 2) textAngle = -(-Math.PI - textAngle);

            const label = `${link.value}`;

            // estimate fontSize to fit in link length
            ctx.font = "1px Sans-Serif";
            const fontSize = Math.min(
              MAX_FONT_SIZE,
              maxTextLength / ctx.measureText(label).width
            );
            ctx.font = `${fontSize}px Sans-Serif`;
            const textWidth = ctx.measureText(label).width;
            const bckgDimensions = [textWidth, fontSize].map(
              (n) => n + fontSize * 0.2
            ); // some padding

            // draw text label (with background rect)
            ctx.save();
            ctx.translate(textPos.x, textPos.y);
            ctx.rotate(textAngle);

            ctx.fillStyle = "rgba(5, 5, 5, 0.8)";
            ctx.fillRect(
              -bckgDimensions[0] / 2,
              -bckgDimensions[1] / 2,
              ...bckgDimensions
            );

            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "darkgrey";
            ctx.fillText(label, 0, 0);
            ctx.restore();
          }}

          // linkColor={(d) => {
          //   const html = document.querySelector("html");
          //   return html.classList.contains("dark") ? "white" : "black";
          // }}
        />
      </div>
    </div>
  );
};
