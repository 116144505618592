import { useEffect, useRef } from "react";
export const DateSelectComponent = ({
  label,
  dateOptions,
  srcAccessionId,
  tgtAccessionId,
  sendCorrelationScoreToParent,
  isDisabled,
}) => {
  const dateRef = useRef(null);

  const styles = {
    option: {
      color: "black",
    },
  };

  const handleSelect = async (e) => {
    const data = {
      date: e.target.options[e.target.selectedIndex].text,
      score: +e.target.value,
    };

    sendCorrelationScoreToParent(data);
  };

  useEffect(() => {
    if (isDisabled) {
      dateRef.current.value = "";
    }
  }, [isDisabled]);

  return (
    <div>
      <label
        htmlFor="date"
        className={
          isDisabled
            ? "block text-xs font-light uppercase text-white opacity-20"
            : "block text-xs font-light uppercase text-white"
        }
      >
        {label}
      </label>
      <select
        ref={dateRef}
        name="date"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm  bg-transparent border border-white disabled:opacity-20"
        defaultValue=""
        onChange={(e) => handleSelect(e)}
        disabled={
          isDisabled || (!srcAccessionId && !tgtAccessionId) ? true : false
        }
      >
        <option value="" disabled>
          Select date
        </option>
        {dateOptions ? (
          dateOptions.map((d, k) => (
            <option key={k} style={styles.option} value={d[Object.keys(d)[0]]}>
              {Object.keys(d)[0]}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};
