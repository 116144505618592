import { fetchProteinData } from "./fetchProteinData";

const getData = async (data) => {
  const asyncRes = await Promise.all(
    data.map(async (d, i) => {
      const proteinData = await fetchProteinData(d.id);

      const data = {
        id: d.id,
        name: d.name,
        known: d.known,
        docs: d.docs.slice(0,10), // SHOWS ONLY UP TO 10 RELATED DOCS
        protein_label: proteinData["protein_label"],
        score: d.score
      };
      return data;
    })
  );
  return asyncRes;
};
export const fetchCorrelatedProteins = async (query) => {
  //'http://localhost:5000/api/historical_correlations?protein1_id=O60313&protein2_id=O00746' \

  const response = await fetch(
    `/api/correlated_proteins?accession_id=${query}`
  );
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  // console.log(data, "with no context")
  const proteinsWithData = await getData(data["items"]);

  return proteinsWithData;
};

export const fetchCorrelatedProteinsWithContext = async (query, contextQuery) => {
  //'http://localhost:5000/api/historical_correlations?protein1_id=O60313&protein2_id=O00746' \

  const response = await fetch(
    `/api/correlated_proteins?accession_id=${query}&context_control=${contextQuery}`
  );
  const data = await response.json();
  // console.log(data, "with context")

  if (data["error"]) {
    return data["error"];
  }

  const proteinsWithData = await getData(data["items"]);

  return proteinsWithData;
};
