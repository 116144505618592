import { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon, MenuAlt2Icon } from "@heroicons/react/outline";
import { timeFormat } from "d3";
import { formatSunburst } from "../utils/formatSunburst";

// import { SearchIcon } from "@heroicons/react/solid";

import { SearchComponent } from "../components/SearchComponent";
import { DateSelectComponent } from "../components/DateSelectComponent";

import { ZoomableSunburst } from "../components/ZoomableSunburst";
import { TwoNodeViz } from "../components/TwoNodeViz";
import { LineGraph } from "../components/LineGraph";

import { Toggle } from "../components/Toggle";

import { fetchProteinData } from "../utils/fetchProteinData";
import { fetchPMIDs } from "../utils/fetchPMIDs";
import { fetchAccessionFromGeneLabel } from "../utils/fetchAccessionFromGeneLabel";
import { fetchCorrelatedProteinScore } from "../utils/fetchCorrelatedProteinScore";
import { fetchCorrelatedProteins } from "../utils/fetchCorrelatedProteins";
import { fetchExplainRelationships } from "../utils/fetchExplainRelationships";

import { isEmpty } from "../utils/utils";

import vsLogo from "../Vector_Space_BioSci_logo.png";
import iclLogo from "../icl_logo.png";

import { ReactComponent as Icon } from "../two_node_icon_v2.svg";
import { LoadingSpinner } from "../components/LoadingSpinner";

const formatTime = timeFormat("%B %d, %Y");

export default function TwoNode() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [srcData, setSrcData] = useState(null);
  const [srcPMIDs, setSrcPMIDs] = useState(null);
  const [srcQuery, setSrcQuery] = useState(null);
  const [srcAccession, setSrcAccession] = useState(null);
  const [srcCorrelatedProteins, setSrcCorrelatedProteins] = useState(null);
  const [srcSunburst, setSrcSunburst] = useState(null);

  const [tgtData, setTgtData] = useState(null);
  const [tgtPMIDs, setTgtPMIDs] = useState(null);
  const [tgtQuery, setTgtQuery] = useState(null);
  const [tgtAccession, setTgtAccession] = useState(null);
  const [tgtCorrelatedProteins, setTgtCorrelatedProteins] = useState(null);
  const [tgtSunburst, setTgtSunburst] = useState(null);

  const [correlationScoreDates, setCorrelationScoreDates] = useState(null);
  const [correlationScore, setCorelationScore] = useState(null);

  const [proteinRelationship, setProteinRelationship] = useState(null);

  const srcQueryRef = useRef(null);
  const tgtQueryRef = useRef(null);

  const handleSrcClick = async (e) => {
    /* 
      Handles Source input from sidebar
    */
    e.preventDefault();
    setCorrelationScoreDates(null);
    setCorelationScore(null);

    if (!srcQuery) return;
    setSrcPMIDs(null);
    const accession = await fetchAccessionFromGeneLabel(srcQuery);
    if (accession.length > 0) {
      setSrcAccession(accession);
    } else {
      setSrcAccession([{ accession: srcQuery }]);
    }
  };

  const handleTgtClick = async (e) => {
    /* Handles Target input submission from sidebar */
    e.preventDefault();
    setCorrelationScoreDates(null);
    setCorelationScore(null);
    if (!tgtQuery) return;
    setTgtPMIDs(null);
    const accession = await fetchAccessionFromGeneLabel(tgtQuery);
    if (accession.length > 0) {
      setTgtAccession(accession);
    } else {
      setTgtAccession([{ accession: tgtQuery }]);
    }
  };

  const sendCorrelationScoreToParent = (score) => {
    /* Set correlation score from date picker */
    setCorelationScore(score);
  };

  const handleExample = async (e) => {
    /* Handle example selection */

    e.preventDefault();

    if (e.target.dataset.dest === "src") {
      let accession = e.target.dataset.accession;
      setSrcQuery(e.target.text);
      const input = srcQueryRef.current.getElementsByTagName("input");
      input[0].value = accession;

      const correlatedProteins = await fetchCorrelatedProteins(accession);
      setSrcAccession([{ accession: accession }]);
      setSrcCorrelatedProteins(correlatedProteins);
    } else {
      let accession = e.target.dataset.accession;

      setTgtQuery(accession);
      const input = tgtQueryRef.current.getElementsByTagName("input");
      input[0].value = accession;
      const correlatedProteins = await fetchCorrelatedProteins(accession);
      setTgtAccession([{ accession: accession }]);
      setTgtCorrelatedProteins(correlatedProteins);
    }
  };

  const handlePlay = () => {
    /* Handle animation of nodes and timeline */
    var counter = correlationScoreDates.length - 1;
    var i = setInterval(function () {
      // do your thing
      let date = Object.keys(correlationScoreDates[counter])[0];
      setCorelationScore({
        date: date,
        score: +correlationScoreDates[counter][date],
      });

      if (counter === 0) {
        clearInterval(i);
      }
      counter--;
    }, 1000);
  };

  const handleMultipleAccessions = async (e) => {
    /* If there are multiple accession numbers from gene_label */
    e.preventDefault();
    const proteinData = await fetchProteinData(e.target.text);
    proteinData["accession"] = e.target.text;

    if (!isEmpty(proteinData)) {
      if (e.target.dataset.dest === "src") {
        const input = srcQueryRef.current.getElementsByTagName("input");
        input[0].value = proteinData["gene_label"];
        setSrcQuery(e.target.text);
        setSrcData(proteinData);
        setSrcAccession([{ accession: e.target.text }]);
        const pmids = await fetchPMIDs(e.target.text);
        setSrcPMIDs(pmids);
      } else {
        const input = tgtQueryRef.current.getElementsByTagName("input");
        input[0].value = proteinData["gene_label"];
        setTgtQuery(e.target.text);
        setTgtAccession([{ accession: e.target.text }]);
        setTgtData(proteinData);
        const pmids = await fetchPMIDs(e.target.text);
        setTgtPMIDs(pmids);
      }
    } else {
      setSrcData(null);
    }
  };

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    /* set correlation scores when inputs change*/
    setCorelationScore(null);
  }, [srcData, tgtData]);

  useEffect(() => {
    /* Call historical correlations when there are two proteins */
    (async () => {
      if (srcData && tgtData) {
        // console.log("Get historical correlations...");
        const dates = await fetchCorrelatedProteinScore(
          srcAccession[0]["accession"],
          tgtAccession[0]["accession"]
        );

        const relationship = await fetchExplainRelationships(
          srcAccession[0]["accession"],
          tgtAccession[0]["accession"]
        );

        setProteinRelationship(relationship[tgtAccession[0]["accession"]]);

        if (dates.length > 0) {
          setCorrelationScoreDates(dates);
        } else {
          setCorrelationScoreDates(false);
        }
      }
    })();
  }, [srcData, tgtData]);

  useEffect(() => {
    /* when there is an target query, reset data and fetch protein data */
    (async () => {
      setTgtData(null);
      if (tgtAccession && tgtAccession.length === 1) {
        const proteinData = await fetchProteinData(
          tgtAccession[0]["accession"]
        );
        if (!isEmpty(proteinData)) {
          setTgtData(proteinData);
          const pmids = await fetchPMIDs(tgtAccession[0]["accession"]);
          setTgtPMIDs(pmids);
          const correlatedProteins = await fetchCorrelatedProteins(
            tgtAccession[0]["accession"]
          );
          setTgtCorrelatedProteins(correlatedProteins);
        } else {
          setTgtData(null);
        }
      }

      if (tgtAccession && tgtAccession.length === 0) {
        const proteinData = await fetchProteinData(tgtQuery);
        if (!isEmpty(proteinData)) {
          setTgtData(proteinData);
          const pmids = await fetchPMIDs(tgtQuery);
          setTgtPMIDs(pmids);
          const correlatedProteins = await fetchCorrelatedProteins(tgtQuery);
          setTgtCorrelatedProteins(correlatedProteins);
        } else {
          setTgtData(null);
        }
      }
    })();
  }, [tgtAccession]);

  useEffect(() => {
    /* when there is an new source query, reset data and fetch protein data */
    (async () => {
      setSrcData(null);
      if (srcAccession && srcAccession.length === 1) {
        const proteinData = await fetchProteinData(
          srcAccession[0]["accession"]
        );
        if (!isEmpty(proteinData)) {
          setSrcData(proteinData);
          const pmids = await fetchPMIDs(srcAccession[0]["accession"]);
          setSrcPMIDs(pmids);
          const correlatedProteins = await fetchCorrelatedProteins(
            srcAccession[0]["accession"]
          );
          setSrcCorrelatedProteins(correlatedProteins);
        } else {
          setSrcData(null);
        }
      }

      if (srcAccession && srcAccession.length === 0) {
        const proteinData = await fetchProteinData(srcQuery);
        if (!isEmpty(proteinData)) {
          setSrcData(proteinData);
          const pmids = await fetchPMIDs(srcQuery);
          setSrcPMIDs(pmids);
          const correlatedProteins = await fetchCorrelatedProteins(srcQuery);
          setSrcCorrelatedProteins(correlatedProteins);
        } else {
          setSrcData(null);
        }
      }
    })();
  }, [srcAccession]);

  useEffect(() => {
    /* format data into sunburst */
    (async () => {
      if (srcCorrelatedProteins && srcData && srcPMIDs) {
        const sunburstData = await formatSunburst(
          srcData,
          srcPMIDs["sunburst"],
          srcCorrelatedProteins
        );
        setSrcSunburst(sunburstData);
      }
    })();
  }, [srcCorrelatedProteins, srcData, srcPMIDs]);

  useEffect(() => {
    /* format tgt data into sunburst format */
    (async () => {
      if (tgtCorrelatedProteins && tgtData && tgtPMIDs) {
        const sunburstData = await formatSunburst(
          tgtData,
          tgtPMIDs["sunburst"],
          tgtCorrelatedProteins
        );
        setTgtSunburst(sunburstData);
      }
    })();
  }, [tgtCorrelatedProteins, tgtData, tgtPMIDs]);

  useEffect(() => {
    /* format correlation score dates */
    if (correlationScoreDates) {
      let d = correlationScoreDates[0];
      let key = Object.keys(correlationScoreDates[0])[0];

      let obj = {
        date: key,
        score: correlationScoreDates[0][key],
      };
      setCorelationScore(obj);
    }
  }, [correlationScoreDates]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0  bg-gradient-to-b from-[#141b30] to-[#183c6a]" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-full  bg-gradient-to-b from-[#141b30] to-[#183c6a] px-4 pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center">
                  <h1>Protein Network</h1>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-8">
                    <div>
                      <SearchComponent
                        label="Source Protein"
                        query={srcQuery}
                        setQuery={setSrcQuery}
                        submitQuery={handleSrcClick}
                      />
                    </div>
                    <div>
                      <SearchComponent
                        label="Target Protein"
                        query={tgtQuery}
                        setQuery={setTgtQuery}
                        submitQuery={handleTgtClick}
                      />
                    </div>
                    <div>
                      {srcData && tgtData && correlationScoreDates ? (
                        <DateSelectComponent
                          label={"Date"}
                          dateOptions={
                            correlationScoreDates ? correlationScoreDates : null
                          }
                          setQuery={setTgtQuery}
                          submitQuery={handleTgtClick}
                          srcAccessionId={srcData ? srcData.accession : null}
                          tgtAccessionId={tgtData ? tgtData.accession : null}
                          sendCorrelationScoreToParent={
                            sendCorrelationScoreToParent
                          }
                          isDisabled={correlationScoreDates ? false : true}
                        />
                      ) : (
                        <></>
                        // <LoadingSpinner
                        //   width="24"
                        //   text="Getting historical correlation"
                        // />
                      )}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="px-4 border-r border-gray-600 pt-5 flex flex-col flex-grow bg-gradient-to-b from-[#141b30] to-[#183c6a]  overflow-y-auto text-white">
            <div className="flex-shrink-0 flex">
              <h1 className="text-white text-2xl font-bold">Protein Network</h1>
            </div>
            <div className="flex-grow mt-5 flex flex-col">
              <nav className="flex-1 pb-4 space-y-8">
                <Link to="/network">
                  <div className="flex space-x-1 items-center hover:text-black hover:bg-white transition:opacity px-2 py-2  bg-[#183965] transition">
                    <Icon className="fill-gray-500" />
                    <span>Protein Correlation</span>
                  </div>
                  <div>
                    <span className="text-xs text-gray-400">
                      Switch to network view →
                    </span>
                  </div>
                </Link>
                <div>
                  <SearchComponent
                    innerRef={srcQueryRef}
                    label={"Source Protein"}
                    setQuery={setSrcQuery}
                    submitQuery={handleSrcClick}
                  />
                </div>
                <div>
                  <SearchComponent
                    innerRef={tgtQueryRef}
                    label={"Target Protein"}
                    setQuery={setTgtQuery}
                    submitQuery={handleTgtClick}
                  />
                </div>
                <div>
                  {!srcData || !tgtData ? (
                    <DateSelectComponent
                      label={"Date"}
                      dateOptions={
                        correlationScoreDates ? correlationScoreDates : null
                      }
                      setQuery={setTgtQuery}
                      submitQuery={handleTgtClick}
                      srcAccessionId={srcData ? srcData.accession : null}
                      tgtAccessionId={tgtData ? tgtData.accession : null}
                      sendCorrelationScoreToParent={
                        sendCorrelationScoreToParent
                      }
                      isDisabled={correlationScoreDates ? false : true}
                    />
                  ) : srcData && tgtData && correlationScoreDates ? (
                    <DateSelectComponent
                      label={"Date"}
                      dateOptions={
                        correlationScoreDates ? correlationScoreDates : null
                      }
                      setQuery={setTgtQuery}
                      submitQuery={handleTgtClick}
                      srcAccessionId={srcData ? srcData.accession : null}
                      tgtAccessionId={tgtData ? tgtData.accession : null}
                      sendCorrelationScoreToParent={
                        sendCorrelationScoreToParent
                      }
                      isDisabled={correlationScoreDates ? false : true}
                    />
                  ) : correlationScoreDates === false ? (
                    <p>No historical correlations</p>
                  ) : (
                    <LoadingSpinner
                      width="24"
                      text="Fetching historical correlations"
                    ></LoadingSpinner>
                  )}
                </div>
              </nav>

              <div className="pb-8">
                {/* <div className="flex justify-end">
                  <Toggle />
                </div> */}
                <div className="flex flex-col items-center">
                  <div className="h-8">
                    <img
                      className="h-full w-auto"
                      src={iclLogo}
                      alt="Imperial College London Logo "
                    />
                  </div>
                  <div className="h-8">
                    <img
                      className="h-full w-auto"
                      src={vsLogo}
                      alt="Vectorspace Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:pl-64">
          <div className="max-w-4xl mx-auto flex lg:px-8 xl:px-0">
            <main className="flex-1" >
              {/*  Mobile menu/ banner */}
              <div className="lg:hidden flex">
                <div className="flex-grow">
                  <Link to="/network">
                    <div className="flex space-x-3 items-center hover:opacity-80 transition:opacity px-2 py-2  bg-[#183965] transition w-full">
                      <Icon className="fill-gray-500" />
                      <div className="flex flex-col">
                        <span>Protein Correlation</span>
                        <span className="text-xs text-gray-400">
                          Switch to network view →
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    className="p-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div className="py-6">
                <div className="px-4 sm:px-6 md:px-0">
                  {correlationScore ? (
                    <div className="flex space-x-8 flex-col md:flex-row pb-16">
                      <div className="flex flex-col space-y-4">
                        <div>
                          {proteinRelationship && (
                            <>
                              <p className="font-bold">Relationship Type</p>
                              <p>
                                {proteinRelationship["known"]
                                  ? "Known"
                                  : "Hidden"}
                              </p>
                            </>
                          )}
                        </div>
                        <div>
                          <p className="font-bold">Correlation Date</p>
                          <p>{correlationScore["date"]}</p>
                        </div>
                        <div>
                          <p className="font-bold">Correlation Score</p>
                          <p>{correlationScore["score"]}</p>
                        </div>
                        <div>
                          <button
                            onClick={handlePlay}
                            className="w-full inline-flex justify-center items-center px-2.5 py-1.5 shadow-sm hover:text-black text-xs font-light text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-[#183965] transition"
                          >
                            Animate
                          </button>
                        </div>
                      </div>
                      <div>
                        <TwoNodeViz
                          correlationScore={correlationScore["score"]}
                          srcNodeId={srcData["gene_label"]}
                          tgtNodeId={tgtData["gene_label"]}
                          width={dimensions.width < 800 ? 300 : 400}
                        />
                      </div>
                      <div>
                        <LineGraph
                          correlationScoreDates={correlationScoreDates}
                          width="200"
                          correlationScore={correlationScore}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="px-4 sm:px-6 md:px-0">
                  {/* Replace with your content */}
                  <div className="py-4 flex gap-x-12 flex-col md:flex-row md:gap-y-0 gap-y-12 md:px-4">
                    <div className="flex-1">
                      {srcQuery === null ? (
                        <div className="dark:text-gray-400 text-gray-500">
                          <div className="h-24">
                            <p className="">
                              Please use the search input to search for a
                              protein by name, accession id, or an expression to
                              compare correlation scores.
                            </p>
                          </div>
                          <p className="text-sm">Examples of source inputs: </p>
                          <ul className="list-disc list-inside text-sm">
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="src"
                                data-accession="O60313"
                                onClick={handleExample}
                              >
                                OPA1 →
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="src"
                                data-accession="O00746"
                                onClick={handleExample}
                              >
                                NME4 →
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="src"
                                data-accession="O00746"
                                onClick={handleExample}
                              >
                                O60313 →
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="src"
                                data-accession="mitochondria"
                                onClick={handleExample}
                              >
                                Mitochondria →
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : srcAccession && srcAccession.length > 1 ? (
                        <div>
                          <p className="pb-2">Please select a protein:</p>
                          <ul className="list-inside list-disc text-sm">
                            {srcAccession.length > 0 &&
                              srcAccession.map((d, k) => {
                                return (
                                  <li key={k}>
                                    <a
                                      href="#"
                                      className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                      data-dest="src"
                                      onClick={handleMultipleAccessions}
                                    >
                                      {d["accession"]}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      ) : srcQuery && !srcData ? (
                        <LoadingSpinner
                          width="32"
                          text="Fetching protein data"
                        ></LoadingSpinner>
                      ) : (
                        <></>
                      )}
                      {srcData && (
                        <>
                          <div className="flex flex-col space-y-4">
                            <h3 className="font-bold">Source Protein</h3>
                            <div>
                              <p className="font-bold text-2xl">
                                {srcData["gene_label"]}
                              </p>
                              <p className="font-light">
                                {srcData["protein_label"]}
                              </p>
                            </div>
                            <div>
                              {srcData["date_created"] ? (
                                <p className="font-bold titlecase text-xs">
                                  Uniprot Accession ID
                                </p>
                              ) : (
                                <p className="font-bold titlecase text-xs">
                                  Query Term
                                </p>
                              )}
                              <p className="">{srcData["accession"]}</p>
                            </div>
                            {srcData["date_created"] && (
                              <div>
                                <p className="font-bold titlecase text-xs">
                                  Date Created
                                </p>
                                <p className="">
                                  {formatTime(srcData["date_created"])}
                                </p>
                              </div>
                            )}
                            {srcPMIDs ? (
                              <div>
                                <div className="border-white border border-opacity-20">
                                  <ZoomableSunburst
                                    data={srcSunburst}
                                    width={window.innerWidth < 800 ? 300 : 400}
                                  />
                                </div>
                              </div>
                            ) : (
                              <LoadingSpinner
                                width="32"
                                text="Fetching Pubmed IDs"
                              ></LoadingSpinner>
                            )}

                            {!srcData["date_created"] ? (
                              <p>
                                * Classifying known and hidden relationships is
                                currently not supported for non-protein queries.
                              </p>
                            ) : (
                              <></>
                            )}

                            {srcData["function"] && (
                              <div className="">
                                <p className="text-xs  font-bold titlecase">
                                  Function
                                </p>
                                <p className="md:line-clamp-none line-clamp-6">
                                  {srcData["function"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex-1">
                      {tgtQuery === null ? (
                        <div className="dark:text-gray-400 text-gray-500">
                          <div className="h-24"></div>
                          <p className="text-sm">Examples of target inputs: </p>
                          <ul className="list-disc list-inside text-sm">
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="tgt"
                                data-accession="O60313"
                                onClick={handleExample}
                              >
                                OPA1 →
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="tgt"
                                data-accession="O00746"
                                onClick={handleExample}
                              >
                                NME4 →
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="tgt"
                                data-accession="O60313"
                                onClick={handleExample}
                              >
                                O60313 →
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                data-dest="tgt"
                                data-accession="mitochondria"
                                onClick={handleExample}
                              >
                                Mitochondria →
                              </a>
                            </li>
                          </ul>
                        </div>
                      ) : tgtAccession && tgtAccession.length > 1 ? (
                        <div>
                          <p className="pb-2">Please select a protein:</p>
                          <ul className="list-inside list-disc text-sm">
                            {tgtAccession.length > 0 &&
                              tgtAccession.map((d, k) => {
                                return (
                                  <li key={k}>
                                    <a
                                      href="#"
                                      className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                      data-dest="tgt"
                                      onClick={handleMultipleAccessions}
                                    >
                                      {d["accession"]}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      ) : tgtQuery && !tgtData ? (
                        <LoadingSpinner
                          width="32"
                          text="Fetching protein data"
                        />
                      ) : (
                        <></>
                      )}

                      {tgtData && (
                        <>
                          <div className="flex flex-col space-y-4">
                            <h3 className="font-bold">Target Protein</h3>
                            <div>
                              <p className="font-bold text-2xl">
                                {tgtData["gene_label"]}
                              </p>
                              <p className="font-light">
                                {tgtData["protein_label"]}
                              </p>
                            </div>
                            <div>
                              {tgtData["date_created"] ? (
                                <p className="text-xs titlecase font-bold">
                                  Uniprot Accession ID
                                </p>
                              ) : (
                                <p className="text-xs titlecase font-bold">
                                  Query Term
                                </p>
                              )}
                              <p className="">{tgtData["accession"]}</p>
                            </div>

                            {tgtData["date_created"] && (
                              <div>
                                <p className="text-xs font-bold titlecase">
                                  Date Created
                                </p>
                                <p className="">
                                  {formatTime(tgtData["date_created"])}
                                </p>
                              </div>
                            )}
                            {tgtPMIDs ? (
                              <div className="border-white border border-opacity-20">
                                <ZoomableSunburst
                                  data={tgtSunburst}
                                  width={window.innerWidth < 800 ? 300 : 400}
                                />
                              </div>
                            ) : (
                              <LoadingSpinner
                                width="32"
                                text="Fetching Pubmed IDs"
                              ></LoadingSpinner>
                            )}

                            {!tgtData["date_created"] ? (
                              <p>
                                * Classifying known and hidden relationships is
                                currently not supported for non-protein queries.
                              </p>
                            ) : (
                              <></>
                            )}

                            {tgtData["function"] && (
                              <div className="">
                                <p className="font-bold text-xs titlecase">
                                  Function
                                </p>
                                <p className="md:line-clamp-none line-clamp-6">
                                  {tgtData["function"]}
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* /End replace */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
