import { useEffect, useRef, useState } from "react";
import {
  timeParse,
  scaleLinear,
  select,
  range,
  scaleTime,
  extent,
  axisLeft,
  axisBottom,
  line,
  min,
  max,
} from "d3";

export const LineGraph = ({
  correlationScoreDates,
  correlationScore,
  width,
}) => {
  const svgRef = useRef(null);
  const lineRef = useRef(null);
  const [data, setData] = useState(null);

  const formatDates = (scores) => {
    let formattedDates = [];
    for (const i in scores) {
      let date_ = Object.keys(scores[i])[0];
      let date = timeParse("%Y-%m-%d")(date_);

      let obj = {
        date: date,
        value: +scores[i][date_],
      };
      // let obj = {new Date(date): +scores[i][date]}
      formattedDates.push(obj);
    }
    return formattedDates;
  };

  useEffect(() => {
    let data = formatDates(correlationScoreDates);
    setData(data);
  }, []);

  useEffect(() => {
    if (!data) return;
    var x = scaleTime()
      .domain(
        extent(data, function (d) {
          return d.date;
        })
      )
      .range([0, innerWidth + margin.right]);

    let date_ = correlationScore["date"];
    let date = timeParse("%Y-%m-%d")(date_);

    const line = select(lineRef.current);
    // .append("g")
    // .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    line
      .transition()
      .delay(100)
      .attr("x1", x(date) + margin.left)
      .attr("x2", x(date) + margin.left)
      .attr("y1", margin.top)
      .attr("y2", height - margin.bottom)
      .attr("stroke", "#40606c")
      .attr("stroke-width", 4);
  }, [correlationScore]);

  const margin = {
    left: 32,
    top: 32,
    right: 32,
    bottom: 32,
  };

  const height = 150;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  useEffect(() => {
    if (!data) {
      return;
    }
    const svg = select(svgRef.current)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x = scaleTime()
      .domain(
        extent(data, function (d) {
          return d.date;
        })
      )
      .range([0, innerWidth + margin.left]);
    svg
      .append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + innerHeight + ")")
      .call(axisBottom(x).ticks(5));

    // Add Y axis
    var y = scaleLinear()
      .domain([
        min(data, function (d) {
          return +d.value;
        }) < 0 ?min(data, function (d) {
          return +d.value;
        }) : 0 ,
        max(data, function (d) {
          return +d.value;
        }) > 0 ?     max(data, function (d) {
          return +d.value;
        }) > 0 : 0,
      ])
      .range([innerHeight, 0]);
    svg.append("g").attr("class", "axis").call(axisLeft(y).ticks(5));

    // Add the line
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return y(d.value);
          })
      );
  }, [data]);

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        <line ref={lineRef}></line>
      </svg>
    </>
  );
};
