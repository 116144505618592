import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ZoomableSunburst } from "./ZoomableSunburst";
import { timeFormat } from "d3";
import { fetchExplainRelationships } from "../utils/fetchExplainRelationships";
import { fetchProteinData } from "../utils/fetchProteinData";

const formatTime = timeFormat("%B %d, %Y");

export default function CorrelationSlideout({
  edge,
  sendSelectedEdgeToParent,
}) {
  const [open, setOpen] = useState(false);
  const [docs, setDocs] = useState(false);
  const [srcProtein, setSrcProtein] = useState(false);
  const [tgtProtein, setTgtProtein] = useState(false);

  const handleclick = () => {
    if (setOpen === false) {
      setOpen(true);
    } else {
      setOpen(false);
      let timer1 = setTimeout(() => sendSelectedEdgeToParent(null), 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  };

  useEffect(() => {
    setOpen(true);
    // console.log(protein["pmids"]["sunburst"]["children"].length)
    (async () => {
      const response = await fetchExplainRelationships(
        edge["source"]["id"],
        edge["target"]["id"]
      );

      const responseSrc = await fetchProteinData(edge["source"]["id"]);
      setSrcProtein(responseSrc);
      const responseTgt = await fetchProteinData(edge["target"]["id"]);
      setTgtProtein(responseTgt);

      setDocs(response[edge["target"]["id"]]);
    })();
  }, [edge]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-[999]"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col dark:bg-[#171d32]/80 bg-[#171d32]/95 shadow-xl overflow-y-scroll">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <div>
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-bold text-white"
                        >
                          {/* {protein["data"]["gene_label"]} */}
                          Relationship
                        </h2>

                        <p className="text-sm font-light text-gray-300">
                          {`${srcProtein["gene_label"]} > ${tgtProtein["gene_label"]}`}
                        </p>
                      </div>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                          onClick={handleclick}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                        <div>
                          <dt className="text-sm font-medium text-white  sm:w-40 sm:flex-shrink-0">
                            Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-300 sm:col-span-2">
                            {edge["known"] ? "Known" : "Hidden"}
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-white  sm:w-40 sm:flex-shrink-0">
                            Strength
                          </dt>
                          <dd className="mt-1 text-sm text-gray-300 sm:col-span-2">
                            {edge["value"]}
                          </dd>
                        </div>
                        <div>
                          {docs && docs["docs"].length > 0 ? (
                            <>
                              <div className="mt-1 text-sm text-gray-300  sm:col-span-2">
                                {docs["docs"].some(
                                  (d) => d.type === "uniprot"
                                ) ? (
                                  <>
                                    <dt className="text-sm font-medium text-white sm:w-40 sm:flex-shrink-0">
                                      Related Proteins
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-300 sm:col-span-2">
                                      <ul>
                                        {docs["docs"]
                                          .filter((d) => d.type === "uniprot")
                                          .map((d) => {
                                            return (
                                              <li>
                                                <a
                                                  target="_blank"
                                                  href={d.link}
                                                  className="underline hover:opacity-80 transition:opacity"
                                                >
                                                  {d.name}
                                                </a>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </dd>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div>
                          {docs && docs["docs"].length > 0 ? (
                            <>
                              <div className="mt-1 text-sm text-gray-300  sm:col-span-2">
                                {docs["docs"].some(
                                  (d) => d.type === "pubmed"
                                ) ? (
                                  <>
                                    <dt className="text-sm font-medium text-white sm:w-40 sm:flex-shrink-0">
                                      Related Publications
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-300 sm:col-span-2">
                                      <ul className="space-y-2 list-disc list-inside">
                                        {docs["docs"]
                                          .filter((d) => d.type === "pubmed")
                                          .map((d) => {
                                            return (
                                              <li> 
                                                <a
                                                  target="_blank"
                                                  href={d.link}
                                                  className="underline hover:opacity-80 transition:opacity"
                                                >
                                                  {d.name ? d.name : d.link}
                                                </a>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </dd>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div>
                          {docs && docs["docs"].length > 0 ? (
                            <>
                              <div className="mt-1 text-sm text-gray-300  sm:col-span-2">
                                {docs["docs"].some(
                                  (d) => d.type === "pathway"
                                ) ? (
                                  <>
                                    <dt className="text-sm font-medium text-white sm:w-40 sm:flex-shrink-0">
                                      Related Pathways
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-300 sm:col-span-2">
                                      <ul>
                                        {docs["docs"]
                                          .filter((d) => d.type === "pathway")
                                          .map((d) => {
                                            return (
                                              <li>
                                                <a
                                                  target="_blank"
                                                  href={d.link}
                                                  className="underline hover:opacity-80 transition:opacity"
                                                >
                                                  {d.name}
                                                </a>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </dd>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
