export const fetchProteinData = async (query) => {
  // const dataSrc = 'https://gist.githubusercontent.com/Emceelamb/c7d6287a1830637325bf6e662d67f37c/raw/d8a48fb76991c2c5f50ce6ae1dbc786c7e9aade0/uniprot_opa1_pmid.csv';
  // const dataSrc = 'https://gist.githubusercontent.com/Emceelamb/c7d6287a1830637325bf6e662d67f37c/raw/d8a48fb76991c2c5f50ce6ae1dbc786c7e9aade0/uniprot_pmid_nme4.csv'
  const response = await fetch(`/api/protein_data?accession_id=${query}`);
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  data["protein_data"]["date_created"] !== null ? (
    (data["protein_data"]["date_created"] = new Date(
      data["protein_data"]["date_created"]
    ))
  ) : (
    <></>
  );
  data["protein_data"]["accession"] = query;
  return data["protein_data"];
};
