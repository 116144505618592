import { timeFormat } from "d3";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}
const formatYear = timeFormat("%Y");

const parseDate = (data) => {
  return data.map((d) => (d["pub_date"] = new Date(d["pub_date"])));
};

const formatSunburst = (data, query) => {
  let sunburstData = {
    name: query,
    children: [],
  };

  // Return only 50 PMIDs
  data.slice(0, 50).map((d) => {
    //protein_accession	pmid	predicate	pubdate

    if (!isValidDate(d["pub_date"])) {
      d["pub_date"] = "Other";
    } else {
      d["pub_date"] = formatYear(new Date(d["pub_date"]));
    }

    d["predicate"] = "Citation";

    const predIdx = sunburstData["children"].findIndex(
      (e) => e.name === d["pub_date"]
    );

    const obj = {
      // array format:
      //protein_accession	pmid	predicate	pubdate
      name: d["pmid"],
      date: new Date(d["pub_date"]),
      size: d["score"],
      title: d["title"],
      link: d["link"],
    };

    if (predIdx === -1) {
      // console.log('has: ', d[2], "at ", idx);
      sunburstData["children"].push({
        name: d["pub_date"],
        children: [
          {
            name: d["predicate"],
            children: [
              {
                name: d["pmid"],
                date: new Date(d["pub_date"]),
                size: d["score"],
                title: d["title"],
                link: d["link"],
              },
            ],
          },
        ],
      });
    } else {
      const yearIdx = sunburstData["children"][predIdx]["children"].findIndex(
        (e) => e.name === d["predicate"]
      );

      if (yearIdx === -1) {
        sunburstData["children"][predIdx]["children"].push({
          name: d["predicate"],
          children: [
            {
              name: d["pmid"],
              date: new Date(d["pub_date"]),
              size: d["score"],
              title: d["title"],
              link: d["link"],
            },
          ],
        });
      } else {
        sunburstData["children"][predIdx]["children"][yearIdx]["children"].push(
          obj
        );
      }
    }
    return d;
  });
  return sunburstData;
};

export const fetchPMIDs = async (query) => {
  const response = await fetch(`/api/correlated_pmids?accession_id=${query}`);
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  const parsedDate = parseDate(data["items"]);
  const sunburstFormat = formatSunburst(data["items"], query);

  const pmids = {
    pmids: data["items"],
    sunburst: sunburstFormat,
  };

  return pmids;
};
