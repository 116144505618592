import {
  select,
  arc,
  format,
  timeFormat,
  hierarchy,
  scaleOrdinal,
  scaleLinear,
  partition,
  interpolate,
  schemeBlues,
  schemeDark2,
  zoom,
} from "d3";

import { useState, useEffect, useRef } from "react";

let scaleScore = scaleLinear().domain([0, 1]).range([1, 1.05]);
const formatTime = timeFormat("%m/%d/%Y");

export const ZoomableSunburst = ({ data, width }) => {
  const svgRef = useRef(null);
  const magnificationRef = useRef(null);
  const breadCrumbRef = useRef(null);
  const _format = format(",d");

  const height = width;
  const radius = width / 9;

  const color = scaleOrdinal(schemeBlues[3]);

  // Updates breadcrumbs
  function update_crumbs(d) {
    const breadCrumb = breadCrumbRef.current
    var crumb_container = select(breadCrumb)
      // sections = getNameArray(d);
      console.log(crumb_container)

    // Remove existing crumbs
    remove_crumbs();

    // Append new crumbs
    d.reverse().forEach(function (name) {
      crumb_container.append("span").classed("crumb", true).text(`${name} > `);
    });
  }

  const remove_crumbs = () => {
    let breadCrumb = breadCrumbRef.current
    var crumb_container = select(breadCrumb)
    crumb_container.selectAll(".crumb").remove();
  };

  // Retrieve arc name and parent names
  function getNameArray(p, array) {
    array = array || [];

    console.log(p, "gt m")
    // Push the current objects name to the array
    array.push(p.data.name);

    // console.log(d.target.__data__.parent, "gj")

    // Recurse to retrieve parent names
    if (p.parent) getNameArray(p.parent, array);

    return array;
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const arch = arc()
    .startAngle((d) => {
      return d.current.x0;
    })
    .endAngle((d) => d.current.x1)
    .padAngle((d) => Math.min((d.current.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 20)
    .innerRadius((d) => d.current.y0 * radius)
    .outerRadius((d) => {
      let multiplier = 1;

      return (
        Math.max(d.current.y0 * radius, d.current.y1 * radius - 1) * multiplier
      );
    });

  const partish = (data) => {
    const root = hierarchy(data)
      .sum((d) => d.size)
      .sort((a, b) => b.size - a.size);
    return partition().size([2 * Math.PI, root.height + 1])(root);
  };

  const handleZoom = (e) => {
    const svg = select(svgRef.current);
    svg.select("g").attr("transform", e.transform);
  };

  const zoome = zoom().on("zoom", handleZoom);

  const zoomIn = () => {
    const svg = select(svgRef.current);
    svg.select("g").transition().call(zoome.scaleBy, 1.5);
  };

  const zoomOut = () => {
    const svg = select(svgRef.current);
    svg.select("g").transition().call(zoome.scaleBy, 0.5);
  };

  const resetZoom = () => {
    const svg = select(svgRef.current);
    // svg.select("g").transition().call(zoome.scaleTo, 1);
    svg.select("g").transition().call(zoome.scaleTo, 1);
  };

  const centerZoom = () => {
    const svg = select(svgRef.current);
    svg.select("g").transition().call(zoome.translateTo, 0, 0);
  };

  useEffect(() => {
    const svg = select(svgRef.current);

    if (data) {
      const old_svg = svg.selectAll("g").remove();

      const root = partish(data);

      root.each((d) => (d.current = d));

      const g = svg.append("g");

      const path = g
        .append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("stroke", (d) => {
          while (d.depth > 1) d = d.parent;
          return color(d.data.name);
        })
        .attr("stroke-opacity", (d) => (arcVisible(d) ? 1 : 0))
        .attr("fill", (d) => {
          while (d.depth > 1) d = d.parent;
          return color(d.data.name);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d) ? (d.children ? 0.2 : 0.1) : 0
        )
        .attr("d", (d) => {
          if (d.depth > 3) {
            return "0";
          }
          return arch(d);
        })
        // .on("mouseover", (d,p) => {
        //   if (p.depth > 0) {
        //     let names = getNameArray(p);
        //     update_crumbs(names);
        //     console.log(names)
        //   }
        // });
      if (window.screen.width > 1023) {
        path.attr("filter", "url(#f1)");
      }

      path
        .filter((d) => d.children)
        .style("cursor", "pointer")
        .on("click", clicked);

      path
        .append("title")
        .text((d) => d.data.title)
        .attr("fill", "white");

      path
        .filter((d) => !d.children)
        .style("cursor", "pointer")
        //.on("click", (d)=>{ console.log(d.target.__data__.data.link)})
        .on("click", (d) => {
          return d.target.__data__.data.link
            ? window.open(`${d.target.__data__.data.link}`, "_blank").focus()
            : "";
        });

      svg.call(zoome, svg);

      const label = g
        .append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("dy", "0.35em")
        .attr("font-size", "8px")
        .attr("fill-opacity", (d) => +labelVisible(d.current))
        .attr("transform", (d) => labelTransform(d.current))
        .text((d) => d.data.name)
        .attr("class", (d) => "dark:fill-white fill-black");

      const parent = g
        .append("circle")
        .datum(root)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .style("cursor", "pointer")
        .on("click", clicked);

      function clicked(event, p) {
        parent.datum(p.parent || root);

        //root.each((d) => console.log(d.target, p, "pre"));

        root.each(
          (d) =>
            (d.target = {
              x0:
                Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              x1:
                Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth),
            })
        );

        //root.each((d) => console.log(d.target, "post"));

        const t = g.transition().duration(750);

        path
          .transition(t)
          .tween("data", (d) => {
            const i = interpolate(d.current, d.target);
            return (t) => (d.current = i(t));
          })
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr("stroke-opacity", (d) => (arcVisible(d.target) ? 1 : 0))
          .attr("fill-opacity", (d) =>
            arcVisible(d.target) ? (d.children ? 0.2 : 0.1) : 0
          )
          .attrTween("d", (d) => () => {
            //console.log(d.depth)
            if (p.depth < 1) {
              if (d.depth > 3) {
                return "0";
              }
            }
            return arch(d);
          });

        label
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target);
          })
          .transition(t)
          .attr("fill-opacity", (d) => +labelVisible(d.target))
          .attrTween("transform", (d) => () => labelTransform(d.current));

        const breadCrumb = select(breadCrumbRef.current);
        // console.log("p", p.data.name,"pare", p.parent, p.parent.parent.data.name)
        breadCrumb.selectAll("text").remove();

        // console.log(
        //   p.data.name,
        //   p.parent.data.name,
        //   p.parent.parent.data.name,
        //   p.parent.parent.parent.data.name
        // );

        const breadCrumbText = `
        ${
          p.parent.parent.parent.data.name
            ? p.parent.parent.parent.data.name + " > "
            : ""
        }
        ${p.parent.parent.data.name ? p.parent.parent.data.name + " > " : ""}
        ${p.parent.data.name ? p.parent.data.name + " > " : ""}
        ${p.data.name ? p.parent.data.name : ""}
        `;

        // const breadCrumbText = `${p.parent.data.name} > ${p.data.name}`

        breadCrumb
          .append("text")
          .text(breadCrumbText)
          .attr("x", 20)
          .attr("y", 20)
          .attr("font-size", "12px")
          .attr("fill", "white");
      }

      function arcVisible(d) {
        return d.y1 <= 4 && d.y0 >= 1 && d.x1 > d.x0;
      }

      function labelVisible(d) {
        return d.y1 <= 4 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.1;
      }

      function labelTransform(d) {
        // console.log(d, "LABEL")
        let multiplier = 1;
        // if (d.depth === 2) {
        //   multiplier = scaleScore(d["data"]["children"].length) + 0.01;
        // } else {
        //   multiplier = 1;
        // }
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = ((d.y0 + d.y1) / 2) * radius * multiplier;
        return `rotate(${x - 90}) translate(${y},0) rotate(${
          x < 180 ? 0 : 180
        })`;
      }
      return svg.node();
    }
  }, [data]);

  return (
    <div className="relative">
      {/* <div ref={breadCrumbRef} class="crumbs"></div> */}
      <svg
        ref={svgRef}
        width={width}
        height={height}
        viewBox={`${-width / 2} ${-height / 2} ${width} ${height}`}
      >
        <defs>
          <filter id="f1" x="0" y="0">
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
            <feMerge>
              <feMergeNode in="coloredBlur"></feMergeNode>
              <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
          </filter>
        </defs>
      </svg>
      <div className="text-xs space-y-2 absolute top-0 right-2 h-full pt-2">
        <div
          ref={magnificationRef}
          className="flex flex-col  justify-center"
        >
          <button
            onClick={zoomIn}
            className="border  w-8 h-8 hover:bg-white hover:text-black"
          >
            +
          </button>
          <button
            onClick={zoomOut}
            className="border-l border-r  w-8 h-8 hover:bg-white hover:text-black"
          >
            -
          </button>
          <button
            onClick={resetZoom}
            className="border-l border-r border-t  w-8 h-8 hover:bg-white hover:text-black"
          >
            ↺ 
          </button>
          <button
            onClick={centerZoom}
            className="border  w-8 h-8 hover:bg-white hover:text-black"
          >
          ⌾
          </button>
        </div>
      </div>
    </div>
  );
};
