import { useEffect, useRef } from "react";
import { scaleLinear, select, range } from "d3";

export const TwoNodeViz = ({ correlationScore, width }) => {
  const svgRef = useRef(null);

  const margin = {
    left: 32,
    top: 32,
    right: 32,
    bottom: 32,
  };

  const height = 100;
  const radius = 24;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = width - margin.top - margin.bottom;

  const linkDistScale = scaleLinear().domain([0, 1]).range([innerWidth, 0]);
  const linkStrengthScale = scaleLinear().domain([0, 1]).range([1, 0]);

  const radGrad = (
    <defs>
      <radialGradient id="myGradient">
        <stop offset="10%" stopColor="#0E0F0F" />
        <stop offset="95%" stopColor="#3F7381" />
      </radialGradient>
    </defs>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const svg = select(svgRef.current);

    svg
      .selectAll("rect")
      .data(range(1))
      .join("rect")
      .transition()
      .delay(100)
      .attr("x", radius / 2 + 20)
      .attr("y", radius + 8)
      .attr("width", (d) => linkDistScale(correlationScore))
      .attr("height", 3)
      .attr("class", "dark:fill-white fill-black");

    svg
      .selectAll("circle")
      .data(range(2))
      .join("circle")
      .transition()
      .delay(100)
      .attr("cx", (d, i) => d * linkDistScale(correlationScore) + margin.left)
      .attr("cy", margin.top)
      .attr("r", radius)
      .attr("fill", "url(#myGradient)");

    if(window.screen.width > 640){
      svg.selectAll('circle').attr("filter", "url(#f1)");
    }

    svg
      .selectAll("text")
      .data([1])
      .join("text")
      .transition()
      .delay(100)
      .text((d, i) => correlationScore)
      .attr("x", (d, i) => {
        return (
          (d * linkDistScale(correlationScore) + margin.left) / 2 + radius / 2
        );
      })
      .attr("y", "50")
      .style("text-anchor", "middle")
      .style("font-size", "8px")
      .attr("class", "dark:fill-white fill-black");
  }, [correlationScore]);

  return (
    <>
      <svg ref={svgRef} width={width} height={height}>
        {radGrad}
      </svg>
    </>
  );
};
