/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ToggleDimension = ({ sendDimensionToParent }) => {
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    // setEnabled(!enabled);
    sendDimensionToParent(enabled);
  }, [enabled]);

  return (
    <>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? "bg-gray-500" : "bg-gray-200 bg-[#121718]",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span className="sr-only">Toggle Third Dimension</span>
        <span
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        >
          <span
            className={classNames(
              enabled
                ? "opacity-0 ease-out duration-100"
                : "opacity-100 ease-in duration-200",
              "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className="h-2 w-2 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <rect
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={8}
                width={24}
                height={24}
              />
            </svg>
          </span>
          <span
            className={classNames(
              enabled
                ? "opacity-100 ease-in duration-200"
                : "opacity-0 ease-out duration-100",
              "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
            )}
            aria-hidden="true"
          >
            <svg
              className="w-3 h-3 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={48}
                d="M512.009,106.652c0-0.491-0.019-0.977-0.051-1.457c-0.011-0.166-0.032-0.331-0.047-0.497
			c-0.028-0.313-0.058-0.626-0.099-0.935c-0.031-0.235-0.071-0.468-0.11-0.701c-0.036-0.216-0.073-0.432-0.115-0.646
			c-0.061-0.313-0.132-0.624-0.207-0.935c-0.021-0.087-0.043-0.173-0.066-0.259c-1.492-5.847-5.424-11.125-11.813-13.978
			c-0.5-0.228-1.01-0.434-1.527-0.624c-0.002-0.001-0.005-0.002-0.007-0.003l-0.091-0.033c-0.172-0.062-0.341-0.129-0.515-0.187
			L263.3,1.284c-4.709-1.713-9.872-1.713-14.581,0L14.658,86.398c-0.171,0.057-0.338,0.123-0.507,0.185l-0.098,0.036
			c-0.003,0.001-0.005,0.002-0.008,0.003c-0.517,0.19-1.027,0.395-1.527,0.624c-6.39,2.852-10.322,8.131-11.813,13.978
			c-0.022,0.086-0.044,0.172-0.066,0.259c-0.075,0.31-0.145,0.621-0.207,0.934c-0.043,0.215-0.079,0.432-0.116,0.65
			c-0.038,0.232-0.079,0.464-0.109,0.697c-0.042,0.311-0.071,0.625-0.1,0.941c-0.015,0.164-0.035,0.327-0.046,0.491
			c-0.033,0.486-0.052,0.977-0.052,1.473v298.667c0,8.971,5.612,16.983,14.043,20.049l234.667,85.333
			c0.002,0.001,0.005,0.001,0.007,0.002c1.641,0.601,3.364,1.005,5.135,1.185c0.038,0.004,0.075,0.009,0.113,0.013
			c0.328,0.032,0.658,0.052,0.988,0.068c0.123,0.006,0.245,0.016,0.368,0.02c0.226,0.007,0.453,0.005,0.68,0.005
			c0.227,0,0.454,0.002,0.68-0.005c0.122-0.004,0.245-0.014,0.368-0.02c0.331-0.016,0.661-0.037,0.988-0.068
			c0.038-0.004,0.075-0.009,0.113-0.013c1.771-0.18,3.493-0.584,5.135-1.185c0.002-0.001,0.005-0.001,0.007-0.002l234.667-85.333
			c8.431-3.066,14.043-11.078,14.043-20.049V106.668C512.009,106.663,512.009,106.658,512.009,106.652z M256.009,44.033
			l172.242,62.633L256.009,169.3L83.768,106.667L256.009,44.033z M42.676,137.124l192,69.818v253.267l-192-69.818V137.124z
			 M469.343,390.391l-192,69.818V206.943l128.676-46.791l63.324-23.027V390.391z"
              />
            </svg>
          </span>
        </span>
      </Switch>
    </>
  );
};
