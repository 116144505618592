import { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon, MenuAlt2Icon } from "@heroicons/react/outline";
import { timeFormat } from "d3";

// import { SearchIcon } from "@heroicons/react/solid";

import { Toggle } from "../components/Toggle";
import { SearchComponent } from "../components/SearchComponent";
import { SearchContextComponent } from "../components/SearchContextComponent";

import vsLogo from "../Vector_Space_BioSci_logo.png";
import { ReactComponent as Icon } from "../two_node_icon_v2.svg";

import { fetchAccessionFromGeneLabel } from "../utils/fetchAccessionFromGeneLabel";
import { fetchProteinData } from "../utils/fetchProteinData";
import { fetchCorrelatedProteins, fetchCorrelatedProteinsWithContext } from "../utils/fetchCorrelatedProteins";
import { fetchPMIDs } from "../utils/fetchPMIDs";
import { fetchExplainRelationships } from "../utils/fetchExplainRelationships";

const formatTime = timeFormat("%B %d, %Y");

const SearchExplain = ({
  label,
  setSrcQuery,
  setTgtQuery,
  submitQuery,
  innerRef,
  srcQuery,
  tgtQuery,
}) => {
  return (
    <form onSubmit={submitQuery} ref={innerRef}>
      <label
        htmlFor={label}
        className="block text-xs font-light uppercase text-white"
      >
        {label}
      </label>
      <div className="mt-1 relative rounded-md space-y-2">
        <input
          onChange={(e) => setSrcQuery(e.target.value.toUpperCase())}
          type="text"
          name={label}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm p-2 bg-transparent border uppercase"
          placeholder="Accession ID"
          value={srcQuery}
        />
        <input
          onChange={(e) => setTgtQuery(e.target.value.toUpperCase())}
          type="text"
          name={label}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm p-2 bg-transparent border uppercase"
          placeholder="Accession ID"
          value={tgtQuery}
        />
        <button
          type="Submit"
          className="w-full inline-flex justify-center items-center px-2.5 py-1.5 shadow-sm hover:text-black text-xs font-light text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-[#183965] transition "
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default function TwoNode() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [proteinData, setProteinData] = useState(null);
  const [proteinAccessionQuery, setProteinAccessionQuery] = useState(null);

  const [correlatedProteins, setCorrelatedProteins] = useState(null);
  const [correlatedProteinsQuery, setCorrelatedProteinsQuery] = useState(null);
  const [contextQuery, setContextQuery] = useState(null);

  const [correlatedPMIDs, setCorrelatedPMIDs] = useState(null);
  const [correlatedPMIDsQuery, setCorrelatedPMIDsQuery] = useState(null);

  const [explainRelationship, setExplainRelationship] = useState(null);
  const [explainRelationshipQuerySrc, setExplainRelationshipQuerySrc] =
    useState(null);
  const [explainRelationshipQueryTgt, setExplainRelationshipQueryTgt] =
    useState(null);

  const [accessionQuery, setAccessionQuery] = useState(null);
  const [accession, setAccession] = useState(null);

  const handleProtein = async (e) => {
    e.preventDefault();
    setProteinData(null);
    const proteinData = await fetchProteinData(proteinAccessionQuery);
    setProteinData(proteinData);
  };

  const handleAccession = async (e) => {
    e.preventDefault();
    setAccession(null);
    const accession = await fetchAccessionFromGeneLabel(accessionQuery);
    console.log(accession);
    setAccession(accession);
  };

  const handleCorrelatedProtein = async (e) => {
    e.preventDefault();
    setCorrelatedProteins(null);
    if(!contextQuery) {
      const proteins = await fetchCorrelatedProteins(correlatedProteinsQuery);
      const sorted = proteins.sort((a, b) => a.known - b.known);
    setCorrelatedProteins(sorted);
    } else {
      const proteins = await fetchCorrelatedProteinsWithContext(correlatedProteinsQuery, contextQuery);
      const sorted = proteins.sort((a, b) => a.known - b.known);
    setCorrelatedProteins(sorted);
    }
  };

  const handleCorrelatedPMID = async (e) => {
    e.preventDefault();
    setCorrelatedPMIDs(null);
    const pmids = await fetchPMIDs(correlatedProteinsQuery);
    console.log(pmids);
    setCorrelatedPMIDs(pmids);
  };

  const handleExplain = async (e) => {
    e.preventDefault();
    setExplainRelationship(null);
    const explain = await fetchExplainRelationships(
      explainRelationshipQuerySrc,
      explainRelationshipQueryTgt
    );
    console.log(explain)
    setExplainRelationship(explain);
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0  bg-gradient-to-b from-[#141b30] to-[#183c6a]" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-full  bg-gradient-to-b from-[#141b30] to-[#183c6a] px-4 pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center">
                  <h1>Protein Network</h1>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-8">
                    <div></div>
                    <div></div>
                    <div></div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="px-4 border-r border-gray-600 pt-5 flex flex-col flex-grow bg-gradient-to-b from-[#141b30] to-[#183c6a]  overflow-y-auto text-white">
            <div className="flex-shrink-0 flex">
              <h1 className="text-white text-2xl font-bold">Protein Network</h1>
            </div>
            <div className="flex-grow mt-5 flex flex-col">
              <nav className="flex-1 pb-4 space-y-8">
                <Link to="/">
                  <div className="flex space-x-1 items-center hover:opacity-80 transition:opacity px-2 py-2  bg-[#183965] transition">
                    <Icon className="fill-gray-500" />
                    <span>Testing</span>
                  </div>
                </Link>
                <div></div>
                <div></div>
                <div></div>
              </nav>

              <div className="pb-8">
                <div className="flex justify-end">
                  <Toggle />
                </div>
                <img src={vsLogo} alt="Vectorspace Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="md:pl-64">
          <div className="max-w-4xl mx-auto flex md:px-8 xl:px-0">
            <main className="flex-1">
              {/*  Mobile menu/ banner */}
              <h1 className="text-2xl mt-16">Testing</h1>
              <div className="mt-8 space-y-8 divide-y">
                <div>
                  <h2 className="text-lg">Protein Data</h2>
                  <p className="text-sm">
                    Returns a JSON with all the relevant details of the protein
                    that the team has gathered.
                  </p>
                  <div>
                    <SearchComponent
                      label="Accession ID"
                      query={proteinAccessionQuery}
                      setQuery={setProteinAccessionQuery}
                      submitQuery={handleProtein}
                    />
                    {proteinData && (
                      <div className="p-8 border rounded">
                        <h3 className="font-bold">Response: </h3>
                        <p>Gene Label: {proteinData["gene_label"]}</p>
                        <p>Protein Label: {proteinData["protein_label"]}</p>
                        <p>Accession ID: {proteinData["accession"]}</p>
                        <p>Function: {proteinData["function"]}</p>
                      </div>
                    )}
                  </div>
                </div>


                <div className="space-y-4 pt-8">
                  <div>
                    <h2 className="text-lg">Accession ID from Gene Label</h2>
                    <p className="text-sm">Get accession id from gene label</p>
                  </div>
                  <div>
                    <SearchComponent
                      label="Gene Label"
                      query={accessionQuery}
                      setQuery={setAccessionQuery}
                      submitQuery={handleAccession}
                    />
                  </div>
                  {accession && (
                    <div className="p-8 border rounded">
                      <h3>Response: </h3>
                      <ul className="list-disc list-inside">
                        {accession.map((d, k) => {
                          return <li key={k}>{d["accession"]}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="space-y-4 pt-8">
                  <div>
                    <h2 className="text-lg">Correlated Proteins</h2>
                    <p>
                      Returns a list of all the PubMed articles that correlates
                      with the passed accession
                    </p>
                  </div>
                  <div>
                    <SearchContextComponent
                      label="Accession ID"
                      query={correlatedProteinsQuery}
                      setQuery={setCorrelatedProteinsQuery}
                      contextQuery={contextQuery}
                      setContextQuery={setContextQuery}
                      submitQuery={handleCorrelatedProtein}
                    />
                  </div>
                  {correlatedProteins && (
                    <div className="p-8 border rounded">
                      <h3>Response: </h3>
                      <ul className="list-disc">
                        {correlatedProteins.map((d, k) => {
                          return (
                            <li key={k}>
                              <div className="list-inisde">
                                <p>{d["name"]}</p>
                                <p>{d["id"]}</p>
                                <p>Known: {d["known"] ? "true" : "false"}</p>
                                <p>
                                  {d["known"] ? (
                                    d["docs"].map((i, k) => {
                                      return (
                                        <>
                                          <p>Docs:</p>
                                          <ul
                                            key={k}
                                            className="list-disc list-inside"
                                          >
                                            <li>ID: {i["id"]}</li>
                                            <li>Type: {i["type"]}</li>
                                            <li>Link: {i["link"]}</li>
                                          </ul>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="space-y-4 pt-8">
                  <div>
                    <h2 className="text-lg">Correlated PMIDs</h2>
                    <p>
                      Returns a list of all the PubMed articles that correlates
                      with the passed accession
                    </p>
                  </div>
                  <div>
                    <SearchComponent
                      label="Accession ID"
                      query={correlatedPMIDsQuery}
                      setQuery={setCorrelatedPMIDsQuery}
                      submitQuery={handleCorrelatedPMID}
                    />
                  </div>
                  {correlatedPMIDs && (
                    <div className="p-8 border rounded">
                      <h3>Response: </h3>
                      <ul className="list-disc space-y-4">
                        {correlatedPMIDs["pmids"].map((d, k) => {
                          console.log(d);
                          return (
                            <li key={k}>
                              <p className="font-bold">
                                {d["title"] ? d["title"] : "Untitled"}
                              </p>
                              <ul>
                                <li>PMID: {d["pmid"]}</li>
                                <li>Date: {d["pub_date"]}</li>
                                <li>Correlation Score: {d["score"]}</li>
                                <li>Link: {d["link"]}</li>
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="space-y-4 pt-8">
                  <div>
                    <h2 className="text-lg">Explain Relationship</h2>
                    <p>
                      Given an accession ID as query, explain the relationships
                      of comma delimited proteins
                    </p>
                  </div>
                  <div>
                    <SearchExplain
                      label="Accession ID"
                      srcQuery={explainRelationshipQuerySrc}
                      tgtQuery={explainRelationshipQueryTgt}
                      setSrcQuery={setExplainRelationshipQuerySrc}
                      setTgtQuery={setExplainRelationshipQueryTgt}
                      submitQuery={handleExplain}
                    />
                  </div>
                  {explainRelationship && (
                    <div className="p-8 border rounded">
                      <h3>Response: </h3>
                      <ul className="list-disc space-y-4">
                        <p>Known: {explainRelationship[explainRelationshipQueryTgt]["known"] ? "True" : "False"}</p>
                        <p>{explainRelationship[explainRelationshipQueryTgt]["known"] ? "Related Docs: " : ""}</p>
                        {explainRelationship[explainRelationshipQueryTgt]["known"] && explainRelationship[explainRelationshipQueryTgt]["docs"].map((d, k) => {
                          return (
                            <>

                            <li key={k}>
                              <p className="font-bold">
                                {d["name"] ? d["name"] : "Untitled"}
                              </p>
                              <ul>
                                <li>ID: {d["id"]}</li>
                                <li>Type: {d["type"]}</li>
                                <li>Link: {d["link"]}</li>
                              </ul>
                            </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
