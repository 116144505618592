export const formatSunburst = (proteinData, pmids, correlatedProteins) => {
  pmids["name"] = "Pubs.";

  // TODO: Fix the style
  // ! {id: 'Q9H6K4', name: 'OPA3', known: true, docs: Array(3), score: 0.38136056065559387}

  // * Initialize correlated proteins sunburst
  let correlatedProteinsSB = {
    name: "Proteins",
    children: [
      {
        name: "known",
        children: [],
      },
      {
        name: "hidden",
        children: [],
      },
    ],
  };

  // * Loop through corr. proteins and categorize by known and hidden
  (async()=>{for await (const i of correlatedProteins) {
    if (i["known"]) {
      let child = {
        name: i["name"],
        children: [],
      };
      // const currentDoc = correlatedProteinsSB[i]["docs"][j]
      // * if known loop through "docs" and add to array

      const childPMID = [];
      const childUniprot = [];
      const childPathway = [];

      for (let j in i["docs"]) {
        const currentDoc = i["docs"][j];
        switch (currentDoc.type) {
          case "pubmed":
            childPMID.push(currentDoc);
            break;
          case "uniprot":
            childUniprot.push(currentDoc);
          default:
            childPathway.push(currentDoc);
        }
      }

      if (childPMID.length > 0) {
        child["children"].push({
          name: "Publications",
          children: [],
        });
        const len = child["children"].length;
        let currentChildDocType = child["children"][len - 1]["children"];
        childPMID.map((d) => {
          currentChildDocType.push({
            name: d["id"],
            link: d["link"],
            size: 0.1,
          });
        });
      }

      if (childUniprot.length > 0) {
        child["children"].push({
          name: "Proteins",
          children: [],
        });
        const len = child["children"].length;
        let currentChildDocType = child["children"][len - 1]["children"];
        childUniprot.map((d) => {
          currentChildDocType.push({
            name: d["id"],
            link: d["link"],
            size: 0.1,
          });
        });
      }

      if (childPathway.length > 0) {
        child["children"].push({
          name: "Pathways",
          children: [],
        });
        const len = child["children"].length;
        let currentChildDocType = child["children"][len - 1]["children"];
        childPathway.map((d) => {
          currentChildDocType.push({
            name: d["id"],
            link: d["link"],
            size: 0.1,
          });
        });
      }
      correlatedProteinsSB["children"][0]["children"].push(child);
    } else if(i["known"]===false){
      let child = {
        name: i["name"],
        children: [
          {
            name: i["id"],
            title: i["id"],
            size: 0.1,
          },
        ],
      };
      correlatedProteinsSB["children"][1]["children"].push(child);
    } else {

      let child = {
        name: i["name"],
        children: [
          {
            name: i["id"],
            title: i["id"],
            size: 0.3,
          },
        ],
      };
      correlatedProteinsSB["children"].push(child);
    }
  }})()


  let sunburst = {
    name: proteinData["accession"],
    children: [pmids, correlatedProteinsSB],
  };
  return sunburst;
};
