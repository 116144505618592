export const fetchAccessionFromGeneLabel = async (query) => {
  const response = await fetch(
    `api/accessions_from_gene_label?gene_label=${query}`
  );
  const data = await response.json();

  if (data["error"]) {
    return data["error"];
  }

  return data["accessions"][query];
};
