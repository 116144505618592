import { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon, MenuAlt2Icon } from "@heroicons/react/outline";

import { SearchComponent } from "../components/SearchComponent";
import { Toggle } from "../components/Toggle";
import { LoadingSpinner } from "../components/LoadingSpinner";

import { NetworkGraph2D } from "../components/NetworkGraph2D";

import ProteinSlideout from "../components/ProteinSlideout";
import CorrelationSlideout from "../components/CorrelationSlideout";
import { ToggleDimension } from "../components/ToggleDimension";

import { fetchNetwork } from "../utils/fetchNetwork";
import { fetchAccessionFromGeneLabel } from "../utils/fetchAccessionFromGeneLabel";

import { isEmpty } from "../utils/utils";

import vsLogo from "../Vector_Space_BioSci_logo.png";
import { ReactComponent as Icon } from "../graph_network_icon_v2.svg";

export default function Network2D() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);
  const [selectedProtein, setSelectedProtein] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [thirdDimension, setThirdDimension] = useState(true);
  const queryRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [isSearching, setIsSearching] = useState(false);

  const [queryAccession, setQueryAccession] = useState(null);

  const handleClick = async (e) => {
    e.preventDefault();

    const response = await fetchAccessionFromGeneLabel(query);

    console.log(response, "THeresp");

    setQueryAccession(response.length > 0 ? response : [query]);

    // const pmids = await response.json();
    // const graphNetwork = await fetchNetwork(query);

    // if (isEmpty(graphNetwork)) {
    //   setData(null);
    // } else {
    //   setData(graphNetwork);
    // }
  };

  const handleExample = async (e) => {
    e.preventDefault();

    setQuery(e.target.text);

    // ! Handle multiple accessions to ID
    const accessions = await fetchAccessionFromGeneLabel(e.target.text);
    const accession = accessions[0]["accession"];
    setQueryAccession(accessions);
  };

  const handleMultipleAccessions = async (e) => {
    e.preventDefault();

    const graphNetwork = await fetchNetwork(e.target.text);
    setData(graphNetwork);
    setQueryAccession([{ accession: e.target.text }]);
  };

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
  });

  // useEffect(() => {
  //   (async () => {
  //     const graphNetwork = await fetchNetwork(queryAccession[0]["accession"]);

  //     if (isEmpty(graphNetwork)) {
  //       setData(null);
  //     } else {
  //       setData(graphNetwork);
  //     }
  //   })();
  // }, [queryAccession]);

  useEffect(() => {
    (async () => {
      setData(null);
      if (queryAccession && queryAccession.length === 1) {
        const networkGraph = await fetchNetwork(
          queryAccession[0]["accession"]
            ? queryAccession[0]["accession"]
            : queryAccession
        );
        setData(networkGraph);
      } 
    })();
  }, [queryAccession]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0  bg-gradient-to-b from-[#141b30] to-[#183c6a]" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative w-full  bg-gradient-to-b from-[#141b30] to-[#183c6a] px-4 pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center">
                  <h1>Protein Network</h1>
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-8">
                    <div>
                      <SearchComponent
                        label={"Root Protein"}
                        setQuery={setQuery}
                        submitQuery={handleClick}
                        query={query ? query : null}
                      />
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="px-4 border-r border-gray-600 pt-5 flex flex-col flex-grow bg-gradient-to-b from-[#141b30] to-[#183c6a]  overflow-y-auto text-white">
            <div className="flex-shrink-0 flex">
              <h1 className="text-white text-2xl font-bold">Protein Network</h1>
            </div>

            <div className="flex-grow mt-5 flex flex-col">
              <nav className="flex-1 pb-4 space-y-8">
                <Link to="/">
                  <div className="flex space-x-1 items-center hover:opacity-80 transition:opacity px-2 py-2 bg-[#183965] transition">
                    <Icon className="fill-gray-500" />
                    <span>Network Graph</span>
                  </div>
                </Link>
                <div>
                  <SearchComponent
                    label={"Root Protein"}
                    setQuery={setQuery}
                    submitQuery={handleClick}
                    innerRef={queryRef}
                    query={query ? query : null}
                  />
                </div>
                <div className="items-center flex space-x-2">
                  <p className="block text-xs font-light uppercase text-white">
                    Graph Dimensions
                  </p>
                  <ToggleDimension sendDimensionToParent={setThirdDimension} />
                </div>
                {/* <div>
                  <DateSelectComponent label={"Date"} />
                </div> */}
              </nav>

              <div className="pb-8">
                <div className="flex justify-end">
                  <Toggle />
                </div>
                <img src={vsLogo} alt="Vectorspace Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="md:pl-64">
          <div className="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
            <main className="flex-1">
              {/*  Mobile menu/ banner */}
              <div className="md:hidden flex">
                <div className="flex-grow">
                  <Link to="/">
                    <div className="flex space-x-1 items-center hover:opacity-80 transition:opacity px-2 py-2 bg-[#183965] transition">
                      <Icon className="fill-gray-500" />
                      <span>Network Graph</span>
                    </div>
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    className="p-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div className="py-6">
                <div className="px-4 sm:px-6 md:px-0">
                  {/* Replace with your content */}
                  <div className="py-4 flex gap-x-12 flex-col">
                    {queryAccession === null ? (
                      <div className="dark:text-gray-400 text-gray-500">
                        <p>Search for a root node.</p>
                        <p className="text-sm">Eg: </p>
                        <ul className="list-disc list-inside text-sm">
                          <li>
                            <a
                              href="#"
                              className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                              data-dest="src"
                              onClick={handleExample}
                            >
                              OPA1
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                              data-dest="src"
                              onClick={handleExample}
                            >
                              NME4
                            </a>
                          </li>
                        </ul>
                      </div>
                    ) : queryAccession.length > 1 ? (
                      <div>
                        <p className="pb-2">Please select a protein:</p>
                        <ul className="list-inside list-disc text-sm">
                          {queryAccession.map((d, k) => {
                            return (
                              <li key={k}>
                                <a
                                  href="#"
                                  className="hover:text-gray-700 hover:font-bold hover:dark:text-white transition"
                                  onClick={handleMultipleAccessions}
                                >
                                  {d["accession"]}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}

                    {query && data ? (
                      <>
                        <NetworkGraph2D
                          data={data}
                          sendSelectedProteinToParent={setSelectedProtein}
                          width={dimensions.width < 800 ? 360 : 800}
                          thirdDimension={thirdDimension}
                          sendSelectedEdgeToParent={setSelectedEdge}
                        />
                      </>
                    ) : // <NetworkGraph2D
                    //   data={data}
                    //   sendSelectedProteinToParent={setSelectedProtein}
                    //   width={dimensions.width < 800 ? 360 : 800}
                    // />
                    queryAccession && queryAccession.length === 1 ? (
                      <LoadingSpinner
                        width="32"
                        text="Generating graph network."
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {selectedEdge ? (
                    <CorrelationSlideout
                      edge={selectedEdge}
                      sendSelectedEdgeToParent={setSelectedEdge}
                    />
                  ) : (
                    <></>
                  )}
                  {selectedProtein ? (
                    <ProteinSlideout
                      protein={selectedProtein}
                      sendSelectedProteinToParent={setSelectedProtein}
                    />
                  ) : (
                    <></>
                  )}
                  {/* /End replace */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
