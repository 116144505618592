export const fetchCorrelatedProteinScore = async (query1, query2) => {
  //'http://localhost:5000/api/historical_correlations?protein1_id=O60313&protein2_id=O00746' \

  const response = await fetch(
    `/api/historical_correlations?protein1_id=${query1}&protein2_id=${query2}`
  ).catch(handleError);
  const data = await response.json().catch(handleError);


  if (data["error"]) {
    return data["error"];
  }

  return data["items"];
};

const handleError = function (err) {
// returns errorr 500 
	console.warn(err);
  return {
    "error": 500
  }
};