import { ForceGraph3D } from "react-force-graph";

import { useState, useRef, useEffect } from "react";
import { scaleLinear } from "d3";
import SpriteText from "three-spritetext";

import { fetchProteinData } from "../utils/fetchProteinData";
import { fetchPMIDs } from "../utils/fetchPMIDs";
import { fetchCorrelatedProteins } from "../utils/fetchCorrelatedProteins";
import { formatSunburst } from "../utils/formatSunburst";

import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

export const NetworkGraph = ({
  data,
  thirdDimension,
  sendSelectedProteinToParent,
  sendSelectedEdgeToParent,
  width,
}) => {

  const [graphData, setGraphData] = useState(data)

  const forceGraph = useRef(null);

  const myScale = scaleLinear().domain([1, 0]).range([0, 150]);
  const colorScale = scaleLinear().domain([1, 10]).range(["#eff2f2", "red"]);
  const extraRenderers = [new CSS2DRenderer()];

  const getProtein = async (gene_label) => {
    const data = await fetchProteinData(gene_label);
    const pmids = await fetchPMIDs(gene_label);
    const proteins = await fetchCorrelatedProteins(gene_label);
    const sunburst = await formatSunburst(data, pmids["sunburst"], proteins);

    const joined = {
      data: data,
      pmids: sunburst,
    };
    sendSelectedProteinToParent(joined);
  };

  useEffect(() => {
    forceGraph.current.d3Force("charge").strength(-500);

    const bloomPass = new UnrealBloomPass();
    bloomPass.strength = 0.5;
    bloomPass.radius = 2;
    bloomPass.threshold = 0.5;
    forceGraph.current.postProcessingComposer().addPass(bloomPass);
  }, []);

  const handleClick = (node) => {
    // Aim at node from outside it
    getProtein(node.id);
    if (thirdDimension) {
      const distance = 100;
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      forceGraph.current.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        3000 // ms transition duration
      );
    }
  };

  const handleEdgeClick = (link, event) => {
    sendSelectedEdgeToParent(link);
  };


  useEffect(() => {
    if (data["nodes"].length < 1) {
      console.log("not loaded");
      return <>Loading</>;
    }
    // forceGraph.current.d3Force("charge").strength(-100);
    forceGraph.current.d3Force("link").distance((link) => { return myScale(link.value)});
    // forceGraph.current.d3Force("link").distance(link => console.log(link));
  });

  const filterKnown = () => {
    let knownNodes = data["nodes"].filter((d)=> d.known === true || d.known==="parent")
    let knownLinks = data["links"].filter((d)=> d.known ===true)

    const filtered = {
      "nodes": knownNodes,
      "links": knownLinks
    }
    setGraphData(filtered)
  }

  const filterHidden= () => {
    let hiddenNodes = data["nodes"].filter((d)=> d.known === false || d.known==="parent")
    let hiddenLinks = data["links"].filter((d)=> d.known === false)

    const filtered = {
      "nodes": hiddenNodes,
      "links": hiddenLinks
    }
    setGraphData(filtered)
  }

  const filterAll= () => {
    setGraphData(data)
  }

  return (
    <div className="block">
      <div className="flex space-x-4 mb-4 ">
        <button className=" text-xs bg-black border-white border px-4 py-2  hover:opacity-80 transition:opacity bg-[#020202] transition" onClick={filterAll}>
          All Connections
        </button>
        <button className="text-xs bg-[#518cc8] px-4 py-2 hover:opacity-80 transition:opacity transition" onClick={filterKnown}>
          Known Connections
        </button>
        <button className="text-xs bg-[#9cba7a] px-4 py-2 hover:opacity-80 transition:opacity transition" onClick={filterHidden}>
          Hidden Connections
        </button>
      </div>
      <div>
        <ForceGraph3D
          numDimensions={thirdDimension ? 3 : 2}
          ref={forceGraph}
          extraRenderers={extraRenderers}
          graphData={graphData}
          width={width}
          nodeLabel="id"
          nodeColor={d => d["known"] === true ? "#518cc8" : d["known"] === false ? "#9cba7a" : d["known"] === "parent" ? "#798d9f" : "lightgray"}
          onNodeClick={handleClick}
          nodeResolution={32}
          nodeThreeObject={(node) => {
            const sprite = new SpriteText(
              `${node.gene_label}\n${node.protein_label}`
            );
            sprite.color = "white";

            sprite.textHeight = 3.5;
            sprite.position.z = 10;
            return sprite;
            // const nodeEl = document.createElement("div");
            // nodeEl.innerHTML = `<strong>${node["gene_label"]}</strong><br>${node["protein_label"]}`;
            // nodeEl.style.color = "white";
            // nodeEl.className = "node-label";
            // return new CSS2DObject(nodeEl);
          }}
          nodeThreeObjectExtend={true}
          linkThreeObjectExtend={true}
          onLinkClick={handleEdgeClick}
          linkColor={d=> d["known"] === true ? "#518cc8" : d["known"] === false ? "#9cba7a" : "#798d9f"}
          linkWidth={0.75}
          // linkColor={(d) => {
          //   const html = document.querySelector("html");
          //   return html.classList.contains("dark") ? "white" : "black";
          // }}
          linkThreeObject={(link) => {
            // extend link with text sprite

            const html = document.querySelector("html");
            const sprite = new SpriteText(`${link.value}`);
            sprite.color = html.classList.contains("dark") ? "gray" : "black";
            sprite.textHeight = 3.5;
            return sprite;
          }}
          linkPositionUpdate={(sprite, { start, end }) => {
            const middlePos = thirdDimension
              ? Object.assign(
                  ...["x", "y", "z"].map((c) => ({
                    [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
                  }))
                )
              : Object.assign(
                  ...["x", "y"].map((c) => ({
                    [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
                  }))
                );

            // Position sprite
            Object.assign(sprite.position, middlePos);
          }}
        />
      </div>
    </div>
  );
};
